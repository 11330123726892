//color palette
// $primary: #3f51b5;
// $secondary: #f50057;
// $error: #f44336;
// $warning: #ff9800;
// $info: #2196f3;
// $success: #4caf50;
$bright-red: #ec0018;
$cherry-red: #f50057;
$gustave: #8ce2d0;
$margot: #ffd2d4;
$light: #ffffff;
$dark: #250844;
$light-red: #f080a0;