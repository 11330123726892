@import './scss/variables.scss';
:root {
  --bright-red: #EC3C48;
  // --bright-red: #ec0018;
  --light-cherry: #FD5D67;
  --cherry-red: #db003f;
  --gustave: #8ce2d0;
  --margot: #ffd2d4;
  --light: #ffffff;
  --dark: #250844;
  --light-red:#f080a0;
  --light-primary: #FAADB0;
  --handle-position-start: 20%;
}

$number-of-options: 4;

@font-face {
  font-family: 'GilroyBold';
  src: local('GilroyBold'), url(./assets/fonts/GilroyExtraBold.woff2) format('woff2');
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Medium.otf) format('opentype');
}

* {
  color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Gilroy, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.swal2-container {
  &.swal2-backdrop-show {
      background-color: theme('colors.black / 60%');
  }
  .swal2-popup {
      box-shadow: 0 0 10px theme('colors.black / 20%');
      background-color: theme('colors.gray-800');
      color: theme('colors.white');
      text-wrap: balance;
      padding: 0 0 2rem;
      border-radius: 1.25rem;

      button:focus {
          box-shadow: none;
      }
  }
  
  .swal2-html-container {
      // margin: 1.5rem 2rem 0.5rem;
      max-height: 70vh;
      font-size: 1em;
  }
  .swal2-title {
      padding: 2rem 1.25rem 0;
      font-size: 1.375rem;
      font-weight: 500;
      line-height: 1.35;
  }
  .swal2-close {
      &:hover {
          color: theme('colors.white');
      }

      ~ .swal2-title {
          padding-top: 3rem;
      }
  }
}
.App {
  background-color: $bright-red;
  background: url(./assets/images/ebs-bg.jpg) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: Gilroy, sans-serif;
  line-height: 1;
  .title{
    margin: auto;
    text-align: center;
  }
  .appTitle{
    font-family: GilroyBold, sans-serif;
    font-size: 32px;
    font-weight: bolder !important;
    text-transform: uppercase;
    color: white;
    margin-bottom: 5%;
    // padding: 5%;
  }
  .appDescription{
    font-size: medium;
    text-transform: capitalize;
  }
}

.footer{
  width: 100%;
  font-size: small;
  text-align: center;
  // margin-top: auto;
  a{
    color: var(--light-primary);
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
  .footerContent{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: #565E6C;
    margin-top: 1.25rem;
    height: 56px;
  }
}
.home{
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  max-width: 768px;
  width: 100%;
  // padding-top: 2rem;
  padding: 3rem 2.5rem;
  margin: auto;
  .homeLogo{
    // margin-top: 3rem;
  }
  // border: 1px solid var(--light-red);
  .content{
    // padding: 0em 2rem;
    text-align: center;
    margin:  4rem 0 0;
    span{
      font-size: 1.125rem;
      font-weight: 300;
    }
    h4{
      text-transform: uppercase;
      font-family: GilroyBold;
      font-size: 32px;
      margin-top: 1.125rem;      
    }
    p{
      margin-top: .25rem;
      font-size: 1.125rem;
    }
  }
  .mainIcon{
    margin-top: 2.5rem;
    // .bhand{
    //   margin-left: 18%;
    // }
    // .yhand{
    //   margin-left: -32%;
    // }
  }
  .opt{
    width: 100%;
    margin-top: 2.5rem;
    // margin-bottom: auto;
    cursor: pointer;
    text-transform: capitalize;
    text-align: left;
    // width: 25em;
    // padding: 0 1rem;
    .designHome{
      // margin-bottom: 1rem;
      width: 100%;
      p{
        font-size: 1.375rem;
        font-weight: 500;
        line-height: inherit;
      }
      // margin: 0 1rem 1rem;
      // display: flex;
      // align-items: start;
      padding: 1.25rem ;
      background-color: #fcb6b8;
      border-radius: 1.25rem;
      transition-duration: 300ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      &:hover{
        transform: scale(1.05);
      }
    }
    .btnWrapper{
      display: flex;
      column-gap: 0.75rem;
      -moz-column-gap: 0.75rem;
      margin-top: 1.25rem;
      width: 100%;
      // margin-bottom: .5rem;
      // margin: 0 .5rem 1rem;
    }
    .smallBox{
      // width: 10em;
      // display: flex;
      // align-items: start;
      // justify-content: space-around;
      background-color: #ff686f;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      padding: 0.75rem;
      // margin: .5rem;
      border-radius: 1.25rem;
      flex: 1;
      transition-duration: 300ms;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      &:hover{
        transform: scale(1.05);
      }
    }
    // .whatsapp{
    //   margin: 0 .5rem .5rem;
    // }
    .icons{
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.75rem;
      // align-content: center;
      // align-items: start;

    }
  }
}

.formWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 40%;
  // min-height: 90vh;
  margin: auto;
  padding: 2%;
  background-color: var(--bright-red);
  border: 2px solid var(--light-primary);
  border-radius: 12px;
}

form{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  width: 100%;
  /* background-color: var(--dark); */
}

.logo{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  // position: absolute;
  // top: 0;
  // right: 0;
  cursor: pointer;
  .breadcrumb{
    padding: 1%;
    // font-size: medium;
    color: var(--light-primary);
  }
}
.error{
  color: var(--light-primary);
  font-size: medium;
  font-weight: 700;
  font-style: italic;
  // background-color: var(--margot);
  padding: 0 1% ;
  border: 0;
  border-radius: 3px;
}
button.submit{
  width: 100%;
  margin: 10px 0;
  padding: 2%;
  border: 2px solid white;
  border-radius: 5px;
  background-color: $margot;
  /* color: var(--dark); */
  outline: none;
  border: none;

}
.verticalStepper{
  width: 100% !important;
  .stepContainer{
    display: none !important;
    
  }
  .middleStepConnector{
    display: none !important;
  }
.descriptionContainer {
  flex-direction: column !important;
  .description{
    font-size: large !important;
    color: var(--margot) !important;
    font-weight: 900 !important;
  }
}
.wrapper{
  display: flex;
  min-height: 250px;
  flex-direction: column;
  justify-content: space-between;
}
.custom-select-wrapper {
  display: flex;
}
.stepActions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: medium;
  padding: 2% 0;
  border-radius: 5px;
  button{
    padding: 2%;
    border: 2px solid white;
    border-radius: 5px;
    background-color: $margot;
    color: var(--bright-red);
    outline: none;
    border: none;
    cursor: pointer;
    &:disabled{
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(255, 255, 255, 0.5);
      cursor: not-allowed;
    }
  }
}
}
.labelWrapper{
  position: relative;
  display: flex;
  justify-content: space-between;
  text-align: left;
  .stepInfo{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px dotted var(--margot);
    font-size: small;
    color: white;
    text-align: center;
    cursor: pointer;
  }
}
.tooltip {
  position: absolute;
  /* display: inline-block; */
  border: 1px dashed var(--bright-red);
  width: 300px;
  height: fit-content;
  right: 0px;
  top: 90%;
  padding: 5%;
  z-index: 1000;
  background-color: white;
  color: var(--bright-red);
  margin: 1%;
  font-size: small;
  border-radius: 5px;
  display: none;
  &.open{
    display: block;
  }
}

button.submit{
  background-color: var(--margot);
  color: var(--cherry-red);
  font-weight: 700;
  font-size: medium;
  cursor: pointer;
}
// bg color changes as from left to right on hover of submit button

button.submit:hover{
  // background: linear-gradient(90deg, var(--margot) 50%, var(--cherry-red) 50%);
  font-size: large;
  box-shadow: 0 0 10px var(--light-red);
  transition: all 0.3s ease-in-out;
}
label{
  color: var(--light);
  font-size: medium;
  font-weight: 700;
  text-transform: capitalize;
}

input[type="range"]{
  position: relative;
  width: 100%;
}

input[type="range"]::before {
  content: "/31";
  position: absolute;
  top: -10px; /* adjust this value to position the marker above the slider */
  left: 50%; /* adjust this value to position the marker over the desired value */
  width: 2px;
  height: 20px;
  background: red; /* change this to the desired color of the marker */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; /* size of the slider thumb, you can change it */
  height: 20px; /* size of the slider thumb, you can change it */
  background: #4CAF50; /* color of the slider thumb, you can change it */
  border-radius: 50%; /* makes the slider thumb circular */
  cursor: pointer; /* changes the cursor when hovering over the slider thumb */
}

// .loader {
//   position: fixed;
//   left: 0;
//   top: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   font-size: 2em;
//   z-index: 1000;
//   .dot {
//     background-color: #fff;
//     border-radius: 50%;
//     width: 10px;
//     height: 10px;
//     margin: 0 5px;

//     animation: pulse 1.5s infinite ease-in-out;
//   }
//   .dot:nth-child(2) {
//     animation-delay: 0.2s;
//   }

//   .dot:nth-child(3) {
//     animation-delay: 0.4s;
//   }
//   @keyframes pulse {
//     0%, 80%, 100% {
//       transform: scale(0);
//     }
//     40% {
//       transform: scale(1);
//     }
//   }
// }

.loading-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  // flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin: auto;
  z-index: 1000;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 20px;

  div {
    position: absolute;
    top: 40px;
    width:8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  div:nth-child(1) {
    left: 8px;
    animation: loader1 0.6s infinite;
  }

  div:nth-child(2){
    left: 8px;
    animation: loader2 0.6s infinite;
  }
  div:nth-child(3) {
    left: 32px;
    animation: loader2 0.6s infinite;
  }

  div:nth-child(4) {
    left: 56px;
    animation: loader3 0.6s infinite;
  }
}



@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
// input checkkbos design
.fill-check{

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@media screen and (max-width: 1424px) {
  .formWrapper{
    width: 50%;
  }
  .App{
    .title{
      // margin: 5% 0 5% 0;
    }
  }
  // .home{
  //   .homeLogo{
  //     margin-top: 8rem;
  //   }
  // }
}

@media screen and (max-width: 768px){
  .formWrapper{
    // margin-top: 12%;
    width: 70%;
  }
  .logo {
    #logo{
      width: 100px;
      height: 50;
    }
    .breadcrumb{
      width: 50px;
      padding: 0 .5rem;
      // height: 30px;
    }
  }
  .home{
    width: auto;
    padding: 2rem 1.25rem;
    // padding-top: 2rem;
    .content{
      margin-top: 3rem;
    }
    .opt{
      // margin-top: 2.5rem;
      // width: 90vw;
      .designHome{
        // margin: 0;
        // padding: 2rem 1.5rem;
        // margin-top: 2.5rem;
        // margin-bottom: 1.25rem;
      }
      .smallBox{
        // padding: .5rem;
        // margin: .25rem;
        font-size: .875rem;
        line-height: 1.25rem;
      }
      
    }
  }
  // .footerContent{
  //   padding: 3%;
  // }
}
@media screen and (max-width: 600px){
  .formWrapper{
    width: 90%;
  }
  .home{
    
    // padding: 3rem .25rem;
    // .homeLogo{
    //   margin-top: 3rem;
    // }
    // .content{
    //   margin: rem 0 0;
    // }
  }
}

// @media screem and (max-width: 1450px){
//   .formWrapper{
//     width: 40vw;
//   }
// }