@import "../../scss/variables.scss";

.dropzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    height: 180px;
    margin: 5% 0;
    border-width: 2px;
    border-radius: 5px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: var(--light-primary);
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
    &.active {
        border-color: #78c800;
      }
    span{
        font-size: 1.25rem;
        font-weight: 700;
        width: 70%;
        text-align: center;
    }
}

@media screen and (max-width: 600px) {
    .dropzone {
        width: 100%;
        span{
            width: 100%;
            font-size: 1rem;
        }
    }
    
}

// custom pointer cursor
.dropzone:hover {
    cursor: pointer;
}


.previewWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px; // adjust as needed
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  
    &:hover {
      opacity: 0.7;
    }

    .resetButton {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 1);
        color: #fff;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        line-height: 10px;
        text-align: center;
        cursor: pointer;
      }
  }