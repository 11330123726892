:root {
	--vs-primary: rgb(29 92 255);
}
dialog {
	position: fixed;
	z-index: 999;
	top: 10%;
	left: 30%;
	width: 40vw;
	background: var(--bright-red);
	max-width: 100%;
	// padding-top: 2rem;
	border-radius: 12px;
	border: 2px solid var(--light-red);
	box-shadow: 0px 45px 50px 10px rgba(0, 0, 0, 0.8);
	animation: fadeIn 1s ease both;
	overflow-y: auto;
	&::backdrop {
		animation: fadeIn 1s ease both;
		background: rgba(251, 250, 250, .1);
		z-index: 200;
		backdrop-filter: blur(50px);
		box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
	}
	// .header{
	// 	display: flex;
	// 	justify-content: space-between;
	// 	align-items: center;
	// 	h2 {
	// 		padding: 1rem;
	// 		color: var(--vs-primary);
	// 		font-size: 1.5rem;
	// 		font-weight: 600;
	// 		background-color: aquamarine;
	// 	}
	
	// }
	.x {
		filter: grayscale(1);
		border: none;
		background: white;
		padding: 1%;
		position: absolute;
		top: 15px;
		right: 10px;
		transition: ease filter, transform 0.3s;
		cursor: pointer;
		transform-origin: center;
		&:hover {
			filter: grayscale(0);
			transform: scale(1.1);
		}
	}
	// h2 {
	// 	width: 100%;
	// 	font-weight: 600;
	// 	font-size: 2rem;
	// 	padding: 1rem ;
	// 	background-color: var(--margot);
	// 	color: var(--bright-red);
	// 	border-radius: 10px 10px 00 0;
	// }
}

.image-gallery-bullets .image-gallery-bullet {
    margin: 0 10px;
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-bullets .image-gallery-bullet.active:hover {
        background: #f64f64;
	}
}

@media (hover: hover) and (pointer: fine) {
	@media (hover: hover) and (pointer: fine) {
		.image-gallery-bullets .image-gallery-bullet:hover {
			background: #f64f64;
			border: 1px solid #f64f64;
		}
	}
}
.image-gallery-bullets .image-gallery-bullet.active {
    transform: scale(1.2);
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #f27474;
    background:#f64f64;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    height: 48px;
    width: 24px;
	transition: all 0.3s;

}
svg{
	stroke-width: 2;
}

@media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover .image-gallery-svg {
        transform: scale(1.5);
    }
}
/*General Styles*/

// .image-gallery {
// 	display: grid;
// 	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
// 	gap: 1rem;
// 	margin-top: 2rem;
// 	padding-bottom: 2rem;
// 	z-index: 999;
// 	padding: 2%;
// }
.image-container {
	position: relative;
	// margin: 2% ;
	// border: 2px solid var(--margot);
	border-radius: 12px;
}
.image-actions {
position: absolute;
bottom: 0;
left: 0;
right: 0;
display: flex;
justify-content: space-between;
padding: 0.5rem;
z-index: 10;
// background-color: rgba(0, 0, 0, 0.5);
}
.viewImg {
padding: 0.3rem;
margin: 0 0.5rem;
border: none;
background-color: white;
color: white;
cursor: pointer;

}

// .info {
// 	border-radius: 5px;
// 	// width: 220px;
// 	// height: 30px;
// 	text-align: left;
// 	display: flex;
// 	align-items: center;
// 	padding: 10px;
// 	background-color: var(--bright-red);
// 	cursor:pointer;
// 	box-shadow:  9px 9px 18px var(--cherry-red),
// 	  -9px -9px 18px var(--cherry-red);
// 	color: var(--cherry-red);
// 	margin: 20px;
//   }
  
  .rotate {
	animation: spin 2s linear infinite;
  }
  
  .info:hover
  {
	background-color: var(--cherry-red);
	transition:0.5s;
  }
  
@keyframes rotate {
	0% {
	   transform: rotate(0deg);
	 }
	 20% {
	   transform: rotate(90deg);
	 }
	 40% {
	   transform: rotate(0deg);
	 }
	 80% {
	   transform: rotate(-90deg);
	 }
   }
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@media screen and (max-width: 1024px) {
	dialog {
		width:60vw;
		left: 20%;
	}
	
}
@media screen and (max-width: 768px) {
	dialog {
		width: 90vw;
		left: 5%;
		top: 15%;
	}
	
}

