@import "../../scss/variables.scss";

.custom-select-wrapper {
    position: relative;
    display: inline-block;
    user-select: none;
    margin: 2% 0 5% 0;
}

.custom-select {
    //   position: relative;
    display: inline-block;
    width: 100%;
    color: #fff;
    font-size: medium;
    padding: 10px 15px;
    // line-height: 60px;
    background: var(--light-primary);
    border-radius: 4px;
    cursor: pointer;
}

.custom-select-trigger {
    // position: relative;
    display: block;

}

.custom-select-trigger:after {
    position: absolute;
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    top: 50%;
    right: 25px;
    margin-top: -3px;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(45deg) translateY(-50%);
    transition: all .4s ease-in-out;
    transform-origin: 50% 0;
}

.custom-select.opened .custom-select-trigger:after {
    margin-top: 3px;
    transform: rotate(-135deg) translateY(-50%);
}

.custom-options {
    z-index: 100;
    overflow-y: auto;
    max-height: 220px;
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    right: 0;
    min-width: 100%;
    margin: 15px 0;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 10px 2px 1px rgba(199, 123, 123, 0.07);
    background: $light;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    animation: slideUp .5s cubic-bezier(0.4, 0, 0.2, 1) forwards;

}
*::-webkit-scrollbar {
    width: 7px;
  }
  *::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 25px;
  }
  
  *::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 25px;
  }
.custom-select.opened .custom-options {
    pointer-events: all;
    animation: slideDown .4s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.custom-options:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 100%;
    right: 25px;
    width: 7px;
    height: 7px;
    margin-bottom: -4px;
    border-top: 1px solid #b5b5b5;
    border-left: 1px solid #b5b5b5;
    background: $light;
    transform: rotate(45deg);
    transition: all .4s ease-in-out;
}

.option-hover:before {
    background: #f9f9f9;
}

.custom-option {
    position: relative;
    display: block;
    padding: 0 22px;
    border-bottom: 1px solid #fff;
    font-size: 14px;
    color: $cherry-red;
    line-height: 3em;
    cursor: pointer;
    transition: all .4s ease-in-out;
    text-transform: capitalize;

}

.custom-option:first-of-type {
    border-radius: 4px 4px 0 0;
}

.custom-option:last-of-type {
    border-bottom: 0;
    border-radius: 0 0 4px 4px;
}

.custom-option:hover,
.custom-option.selection, .custom-option:focus {
    background: var(--light-primary);
    color: white;
    outline: none;
}

@keyframes slideDown {
    0% {
        transform: scaleY(0);
        opacity: 0;
        visibility: hidden;
    }

    100% {
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes slideUp {
    0% {
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
    }

    100% {
        transform: scaleY(0);
        opacity: 0;
        visibility: hidden;
    }
}