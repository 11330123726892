$number-of-options: 4;

#ai-intervention {
    display: flex;
    flex-direction: row;
    align-content: stretch;
    position: relative;
    width: 100%;
    height: 50px;
    user-select: none;
    margin-top: 5%;
    &::before {
      content: " ";
      position: absolute;
      height: 2px;
      width: 100%;
      width: calc(100% * (#{$number-of-options - 1} / #{$number-of-options}));
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--cherry-red);
    }
    input, label {
      box-sizing: border-box;
      flex: 1;
      user-select: none;
      cursor: pointer;
    }
    label {
      display: inline-block;
      position: relative;
      width: 20%;
      height: 100%;
      user-select: none;
      &::before {
        content: attr(data-ai-intervention);
        position: absolute;
        left: 50%;
        padding-top: 10px;
        transform: translate(-50%, -20px);
        font-size: 14px;
        letter-spacing: 0.4px;
        font-weight: 400;
        white-space: nowrap;
        opacity: 0.85;
        transition: all 0.15s ease-in-out;
      }
      &::after {
        content: " ";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        border: 2px solid var(--bright-red);
        background: var(--light);
        color: #4CAF50;
        border-radius: 50%;
        pointer-events: none;
        user-select: none;
        z-index: 10;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
      }
      &:hover::after {
        transform: translate(-50%, -50%) scale(1.25);
      }
    }
    input {
      display: none;
      &:checked {
        + label::before {
          font-weight: 800;
          opacity: 1;
        }
        + label::after {
          border-width: 4px;
          transform: translate(-50%, -50%) scale(0.75);
          background-color: var(--cherry-red);
        }
        ~ #ai-intervention-pos {
          opacity: 1;
        }
        @for $i from 1 through $number-of-options {
          &:nth-child(#{$i * 2 }) ~ #ai-intervention-pos {
            left: #{($i * -50%) };
          }
        }
      }
    }
    #ai-intervention-pos {
      display: block;
      position: absolute;
      top: 50%;
      width: 12px;
      height: 12px;
      background: var(--gustave);
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      transform: translate(50%, -50%);
      border: 2px solid var(--cherry-red);
      opacity: 0;
      z-index: 2;
    }
  }

  @keyframes spin {
	from {
		transform: rotate(0deg);
		width: 24px;
		opacity: 1;
		margin-right: 12px;
	}
	to {
		transform: rotate(360deg);
		width: 24px;
		opacity: 1;
		margin-right: 12px;
	}
}